@use "../../../colors.scss" as *;

.player_buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
    margin: 0 auto;
    align-items: center;
    min-width: 43rem;
    padding-left: 3rem;

    &__row {
        min-width: 43rem;
        display: flex;
        gap: 1.56rem;
        align-items: center;
    }
}

.player_button {
    cursor: pointer;
    opacity: 0.9;
    font-family: "OCR B Std Medium", "Courier New", Courier, monospace;

    &:hover {
        opacity: 1;
    }

    &__num {
        height: 5.563rem;
        width: 5.188rem;
        background-image: url("../../../assets/mode-tile-dark.jpg");
        padding: 0.625rem;
        font-size: 0.75rem;
        background-size: cover;
        color: $light;

        &--neutral {
            background-image: url("../../../assets/mode-tile-neutral.jpg");
        }
    }

    p {
        font-size: 0.625rem;
        margin-top: 0.5rem;
        color: $light;

        display: flex;
        align-items: center;
        gap: 0.25rem;

        &::before {
            content: "";
            display: block;
            width: 0.32rem;
            height: 0.32rem;
            border-radius: 50%;
            background-color: #eaefff;
            box-shadow: inset 0.06rem 0.06rem 0.06rem 0 rgba(#000, 0.25);
        }
    }

    &--active {
        .player_button__num {
            background-image: url("../../../assets/mode-tile-dark-active.jpg");
        }

        .player_button__num--neutral {
            background-image: url("../../../assets/mode-tile-neutral-active.jpg");
        }

        p::before {
            background-color: #fefefe;
            box-shadow:
                0 0 0.5rem 0 $secondary-accent,
                inset 0.06rem 0.25rem 0.06rem 0 $secondary-accent;
        }
    }

    &__generate {
        margin-left: 0.5rem;
        opacity: 0.9;
        cursor: pointer;
        transition: transform 0.2s ease;

        img {
            width: 18.125rem;
            cursor: pointer;
        }

        &:hover {
            opacity: 1;
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }
}

.player_next_back {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    flex-direction: column;

    & > img {
        width: 4rem;
    }

    &__container {
        display: flex;
        gap: 1.625rem;
        opacity: 0.9;

        img {
            width: 2.313rem;
            cursor: pointer;
            transition: 0.2s ease all;

            &:hover {
                transform: scale(0.95);
            }
        }

        &:hover {
            opacity: 1;
        }
    }
}
