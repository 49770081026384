@use "../../../colors.scss" as *;

.subscribe_tile {
    padding: 0.875rem;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    cursor: pointer;
    border-radius: 0.125rem;
    transition: 0.2s ease all;

    img {
        width: 7.5rem;
        height: 7.5rem;
        border-radius: 0.25rem;
    }

    h3 {
        color: white;
        font-size: 1.875rem;

        margin-bottom: 0.5rem;
    }

    p {
        font-size: 1rem;
        color: $neutral;
        line-height: 120%;
    }

    &:not(.subscribe_tile--active):hover {
        outline: 0.125rem solid rgba($neutral, 0.25);
    }

    &--active {
        outline: 0.125rem solid $accent;
    }
}
