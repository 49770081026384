@use "../../../colors.scss" as *;

.auth__form {
    width: 100%;
    max-width: 55.75rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    p {
        text-align: center;
        color: $neutral;
    }

    a {
        color: $accent;

        &:hover {
            text-decoration: underline;
        }
    }
}
