@use "../../../colors.scss" as *;

.remix_info {
    padding: 1rem 0;

    label {
        cursor: pointer;
    }

    input[type="file"] {
        display: none;
    }

    &__details {
        display: flex;
        gap: 2rem;
        align-items: center;
        margin-bottom: 1rem;

        img {
            width: 17.5rem;
            height: 17.5rem;
            object-fit: cover;
        }

        h3 {
            font-size: 1.875rem;
            color: $light;
        }

        p {
            color: $neutral;
        }
    }

    &__inputs {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .select {
            z-index: 8;
        }

        .select:first-of-type {
            z-index: 9;
        }
    }

    &__buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        position: relative;
    }

    &__button {
        font-size: 1rem;
        padding: 0.625rem 1.25rem 0.625rem 1.5rem;
        color: $light;
        background-color: transparent;
        border-radius: 0.125rem;
        transition: border 0.3s ease;
        width: 100%;
        border: 0.06rem solid $light;
        cursor: pointer;
        text-align: center;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            width: 1.75rem;
        }

        &:hover {
            background-color: $light;
            color: $container-bg;
        }

        &:focus {
            outline: none;
        }
    }
}
