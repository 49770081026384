@use "../../colors.scss" as *;

.account {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3rem;
        width: 100%;
    }

    &__file_label {
        position: relative;
        border-radius: 50%;
        cursor: pointer;

        input {
            display: none;
        }
    }

    &__img {
        width: 25rem;
        height: 25rem;
        border-radius: 50%;
        object-fit: cover;
    }

    &__inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        max-width: 55rem;
        gap: 1.375rem;
    }

    &__clear {
        position: absolute;
        top: 3rem;
        right: 3rem;
        cursor: pointer;
    }
}

.cancel_subscription_par {
    font-size: 14px;
    margin-top: .5rem;
    text-align: center;
    color: $accent;
}

.cancel_subscription {
    border: none;
    color: $accent;
    background-color: transparent;
    cursor: pointer;
    font-size: 12px;

    &__container {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
    }
}
