@use "../../../colors.scss" as *;

.cancel_sub {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    &__backdrop {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background-color: rgba(black, 0.5);
    }

    &__body {
        position: relative;
        z-index: 2;
        background-color: $container-bg;
        border: 0.06rem solid $neutral;
        max-width: 45rem;
        width: 100%;
        padding: 3rem;

        h2 {
            color: $light;
            margin-bottom: 1rem;
            text-align: center;
        }

        p {
            color: $neutral-light;
            text-align: center;
            margin-bottom: 2rem;
        }
    }

    &__buttons {
        display: flex;
        gap: 1rem;
    }
}
