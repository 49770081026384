@use "../../../colors.scss" as *;

.google {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 0.25rem;
    padding: 0.625rem;
    border: 0.1rem solid #d9d9d9;
    font-size: 0.75rem;
    color: $neutral;
    transition: color 0.3s ease;
    line-height: 120%;
    border-radius: 3rem;
    margin: 4.5rem auto 7.5rem;
    cursor: pointer;

    img {
        width: 1rem;
        margin-bottom: auto;
    }

    &:hover {
        color: $light;
    }
}
