@use "./colors.scss" as *;
@import url("./fonts/fonts.scss");

html {
    background-color: $container-bg;
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "OCR B Std Medium", "Courier New", Courier, monospace !important;
    text-transform: uppercase;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

a {
    text-decoration: none;
}

input {
    text-transform: none;
}

body {
    min-height: 100vh;
}

img {
    width: auto;
    height: auto;
}

::-webkit-scrollbar {
    width: 0.4rem;
}

*::-webkit-scrollbar-thumb {
    background-color: lighten($container-bg, 5%);
    width: 0.4rem;
}

*::-webkit-scrollbar-track {
    background-color: $container-bg;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
}
