@use "../../../colors.scss" as *;

.post {
    padding-top: 1.75rem;
    padding-bottom: 0.75rem;
    color: $light;
    border-bottom: 0.06rem solid #1b1b1b;

    &:first-child {
        padding-top: 1rem;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    &__img {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
    }

    &__name {
        display: flex;
        gap: 0.25rem;
        font-size: 0.75rem;
        align-items: center;

        img {
            width: 0.75rem;
            height: 0.75rem;
            margin-bottom: 0.25rem;
        }
    }

    &__date {
        font-size: 0.625rem;
        color: $neutral-light;
    }

    &__icons {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 0.75rem;

        img {
            cursor: pointer;
        }
    }

    &__like,
    &__comment {
        display: flex;
        font-size: 0.625rem;
        align-items: center;
        gap: 0.1rem;
        cursor: pointer;

        span {
            margin-top: 0.25rem;
            color: $neutral-light;
        }
    }

    &__par {
        font-size: 1rem;
        margin-top: 0.75rem;
    }

    &__post_img {
        height: 200px;
        width: 100%;
        margin-top: 1rem;
        position: relative;
        object-fit: contain;
        background-color: #0f0f0f;
    }

    &__song_container {
        margin-top: 1rem;
    }

    &__embed_container {
        width: 100%;
        height: auto;
        position: relative;
        background-color: #0f0f0f;
        margin-top: 24px;

        iframe {
            width: 100%;
            aspect-ratio: 560 / 315;
        }
    }

    &__tiktok_container {
        width: 100%;
        height: auto;
        position: relative;
        margin: 0 auto;
        background-color: #0f0f0f;
        display: flex;
        justify-content: center;
        margin-top: 24px;

        iframe {
            height: 615px;
        }
    }
}
