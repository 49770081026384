@use "../../../colors.scss" as *;

.comment {
    padding-top: 1.75rem;
    padding-bottom: 0.75rem;
    color: $light;
    border-bottom: 0.06rem solid #1b1b1b;

    &:first-child {
        padding-top: 1rem;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    &__img {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
    }

    &__name {
        display: flex;
        gap: 0.25rem;
        font-size: 0.75rem;
        align-items: center;

        img {
            width: 0.75rem;
            height: 0.75rem;
            margin-bottom: 0.25rem;
        }
    }

    &__date {
        font-size: 0.625rem;
        color: $neutral-light;
    }

    &__icons {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 0.75rem;

        img {
            cursor: pointer;
        }
    }

    &__like {
        display: flex;
        font-size: 0.625rem;
        align-items: center;
        gap: 0.1rem;
        cursor: pointer;

        span {
            margin-top: 0.25rem;
            color: $neutral-light;
        }
    }

    &__par {
        font-size: 1rem;
        margin-top: 0.75rem;
    }
}
