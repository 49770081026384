@use "../../colors.scss" as *;

.multi_select {
    position: relative;
    z-index: 2;
    cursor: pointer;
    flex: 1;

    &:nth-child(1) {
        z-index: 6;
    }

    &:nth-child(2) {
        z-index: 5;
    }

    &:nth-child(3) {
        z-index: 4;
    }

    &:nth-child(4) {
        z-index: 3;
    }

    img {
        width: 1.5rem;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.75rem 1.5rem;
        border: none;
        border-radius: 0.125rem;
        font-size: 1rem;
        color: white;
        text-transform: none;
        transition: all 0.3s ease;
        height: 100%;
        border: 0.06rem solid $neutral;
    }

    &__body {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        width: 100%;
        font-size: 1.125rem;
        flex: 1;
        border-radius: 0.25rem;
        color: white;
        border: none;
        max-height: 12.5rem;
        overflow: auto;
    }

    &__item {
        width: 100%;
        padding: 1.125rem 1.5rem;
        cursor: pointer;
        background: #131313;
        border-bottom: 1px solid #1d1d1d;

        &:hover {
            background-color: #1d1d1d;
        }
    }

    &__values {
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
    }

    &__value {
        background-color: white;
        color: $container-bg;
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem;
        border-radius: 5rem;

        img {
            width: 1rem;
        }
    }
}
