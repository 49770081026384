@use "../../../colors" as *;

.subscribe_body {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    flex: 1;

    &__tiles {
        display: flex;
        justify-content: space-between;
    }

    &__row {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    a:first-of-type {
        margin-top: auto;
    }

    a,
    button {
        max-width: 55rem;
        width: 100%;
        margin: 0 auto;
    }

    .app_header {
        margin-bottom: 1rem;
    }
}

.subsribe_body__row:nth-child(2) .app_header {
    text-align: end;
    color: $neutral;
}
