.content_container {
    background: #050505;
    flex: 1;
    margin: 0.75rem 0.75rem 0 0.75rem;
    border-radius: 0.75rem;
    padding: 0.75rem 1.5rem;
    overflow: auto;

    &--no_overflow {
        overflow: hidden;
    }

    & > .nav {
        display: none;
    }
}
