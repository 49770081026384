@use "../../../colors.scss" as *;

.user {
    display: flex;
    padding: 0.75rem 1.25rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5rem;
    width: 100%;

    &:hover {
        background-color: rgba(white, 0.05);
    }

    &__info {
        display: flex;
        gap: 1.5rem;
    }

    &__text {
        display: flex;
        gap: 0.875rem;
        align-items: center;
    }

    img {
        width: 3.25rem;
        height: 3.25rem;
        border-radius: 50%;
    }

    h3 {
        font-size: 1.125rem;
        color: $light;
    }

    span {
        font-size: 0.75rem;
        color: $neutral;
    }

    &__buttons {
        display: flex;
        gap: 1.25rem;
    }
}

.user_settings {
    position: relative;

    img {
        width: 1.25rem;
        height: 1.25rem;
        cursor: pointer;
    }

    &__container {
        position: absolute;
        top: 2.5rem;
        right: 0;
        background: lighten($container-bg, 2%);
        padding: 1.5rem;
        border: 0.05rem solid $neutral;
        width: 17rem;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;

        div {
            padding: 0.5rem 0;
        }
    }

    button {
        border: none;
        background-color: transparent;
        font-size: 0.875rem;
        cursor: pointer;
        opacity: 0.8;
        transition: 0.3s all ease;
        color: white;

        &:hover {
            opacity: 1;
            text-decoration: underline;
        }

        &.delete {
            color: $secondary-accent;
        }
    }

    label {
        border: none;
        background-color: transparent;
        font-size: 0.75rem;
        cursor: pointer;
        transition: 0.3s all ease;
        color: $accent;
        margin-bottom: 0.25rem;
        display: inline-block;
    }

    input {
        margin-bottom: 0;
        font-size: 0.875rem;
        padding: 0.625rem 1rem;
        color: $light;
        background-color: transparent;
        border-radius: 0.125rem;
        transition: border 0.3s ease;
        border: 0.06rem solid $neutral;
        width: 100%;
        appearance: none;

        &::placeholder {
            color: $neutral;
        }

        &:focus {
            outline: 0.06rem solid $light;
        }
    }
}
