.app_wrapper.app_wrapper--light {
    background: linear-gradient(351.27deg, #cdcdcc 0.55%, #e4e3e1 84.95%);

    .player_button p {
        color: #0b0b0b;
    }

    .player_header {
        border-bottom: 0.06rem solid #b9b9b9;
    }

    .player_button__num {
        background-image: url("./assets/mode-tile-light.jpg");
        color: #0b0b0b;
    }

    .player_button__num--neutral {
        background-image: url("./assets/mode-tile-neutral-light.jpg");
    }

    .player_button--active {
        .player_button__num {
            background-image: url("./assets/mode-tile-light-active.jpg");
            color: #0b0b0b;
        }

        .player_button__num--neutral {
            background-image: url("./assets/mode-tile-neutral-light-active.jpg");
        }
    }

    .player_controls__play p {
        color: #0b0b0b;
    }

    .post_form__publish p {
        color: #0b0b0b;
    }

    .comment_form__publish p {
        color: #0b0b0b;
    }
}
