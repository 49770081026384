@use "../../../colors.scss" as *;

.ai_inputs {
    display: flex;
    gap: 1.5rem;

    .select {
        flex: 1;
    }

    input {
        padding: 0.75rem 1.5rem;
    }
}

.ai_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5rem;
    margin: 2rem 0;

    input[type="file"] {
        display: none;
    }

    &.active {
        color: $accent;
    }
}

.stem {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.25rem;
    font-size: 1.125rem;
    color: $neutral;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 0.06rem solid $neutral;

    &:hover {
        color: white;
    }

    &.active {
        color: white;
    }

    img {
        width: 1.25rem;
        cursor: auto;
    }
}
