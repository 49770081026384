@use "../../../colors.scss" as *;

.player_header {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 0.06rem solid $container-bg;

    img {
        height: 1.375rem;
    }
}
