.cols {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;

    hr {
        height: 110%;
        position: absolute;
        border: 0.06rem solid #1b1b1b;
    }
}

.col {
    flex: 1;
    overflow: auto;
    height: 100%;
    position: relative;

    &__1 {
        padding-right: 2rem;
    }

    &__2 {
        padding-left: 2rem;
    }
}
