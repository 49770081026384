@use "../../../colors.scss" as *;

.progress_bar input[type="range"] {
    -webkit-appearance: none; /* Remove default appearance */
    width: 100%;
    height: 6px;
    background: linear-gradient(to right, $accent 0%, $accent var(--progress), #0f0f0f var(--progress), #272727 100%);
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

/* The slider thumb */
.progress_bar input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $accent; /* Thumb color */
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    position: relative;
    z-index: 2; /* Make sure thumb is above track */
}

/* For Firefox */
.progress_bar input[type="range"]::-moz-range-thumb {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $accent;
    cursor: pointer;
}

/* For Internet Explorer and Edge */
.progress_bar input[type="range"]::-ms-thumb {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $accent;
    cursor: pointer;
}

/* Hide default track styles in IE and Edge */
.progress_bar input[type="range"]::-ms-track {
    width: 100%;
    height: 6px;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

.progress_bar__time {
    display: flex;
    justify-content: space-between;
    color: $neutral;
    padding: 0.25rem 1rem;
    font-size: 0.75rem;
}
