.subscribe {
    min-height: 90%;
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}
