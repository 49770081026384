@font-face {
    font-family: "OCR B Std Medium";
    src:
        url("./OCR_B_Std_Medium.woff2") format("woff2"),
        url("./OCR_B_Std_Medium.woff") format("woff"),
        url("./OCR_B_Std_Medium.ttf") format("truetype");

    font-display: swap;
    font-style: normal;
}
