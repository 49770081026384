@use "../../../colors.scss" as *;

.song_form {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 100%;
    max-width: 70rem;
    margin: 2rem auto;
    justify-content: center;

    &--ai {
        gap: 1rem;
    }

    &__container {
        display: flex;
        gap: 1.5rem;
        width: 100%;
    }

    &__inputs {
        flex: 1;
        display: flex;
        gap: 1rem;
        flex-direction: column;

        input {
            padding: 0.75rem 1.5rem;
        }
    }

    &__upload {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        padding: 2rem;
        border: 0.06rem solid $neutral;
        border-radius: 0.125rem;
        cursor: pointer;

        img {
            width: 9.375rem;
            height: 9.375rem;
            object-fit: cover;
        }

        p {
            font-size: 1.125rem;
            color: white;
        }

        input {
            display: none;
        }
    }

    &__upload--audio {
        padding: 1.5rem;
    }

    &__upload_wrapper {
        flex: 1;
        display: flex;
        gap: 1.25rem;
        flex-direction: column;
    }

    &__delete {
        cursor: pointer;
        opacity: 0.8;
        color: $secondary-accent;
        background: none;
        border: none;
        transition: 0.3s all ease;

        &:hover {
            opacity: 1;
        }
    }

    &__status {
        cursor: pointer;
        opacity: 0.8;
        color: $accent;
        background: none;
        border: none;
        transition: 0.3s all ease;

        &:hover {
            opacity: 1;
        }
    }

    &__buttons {
        display: flex;
        gap: 1.5rem;
        justify-content: center;
        flex-wrap: wrap;

        button {
            margin: 0;
        }
    }
}
