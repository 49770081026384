@use "../../../colors.scss" as *;

.auth__header {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;

    a {
        font-size: 1.875rem;
        color: $neutral;
        display: flex;
        gap: 0.2rem;
        align-items: center;

        &.active {
            color: white;

            &::before {
                content: "";
                display: block;
                background-color: $light;
                width: 0.313rem;
                height: 0.313rem;
                border-radius: 50%;
                box-shadow:
                    0 0 0.5rem 0 $accent,
                    inset 0.06rem 0.25rem 0.06rem 0 $accent;
            }
        }
    }
}
