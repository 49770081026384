.nav {
    display: flex;
    width: 100%;
    height: 1.2rem;
    margin-bottom: 0.5rem;
    align-items: center;
    justify-content: flex-end;
    gap: 0.375rem;

    img {
        width: 1.25rem;
        cursor: pointer;
    }
}
