@use "../../../colors.scss" as *;

.player_controls {
    display: flex;
    gap: 2.5rem;
    align-items: center;
    width: 50%;
    justify-content: center;

    &__img {
        width: 3.75rem;
        cursor: pointer;
        transition: transform 0.2s ease;
    }

    &__play {
        img {
            width: 8.125rem;
            cursor: pointer;
            transition: transform 0.2s ease;
        }

        p {
            display: flex;
            gap: 0.25rem;
            align-items: center;
            margin-bottom: 1.25rem;
            color: $light;

            font-size: 0.75rem;
            justify-content: center;
            padding-right: 0.75rem;

            &::before {
                content: "";
                display: block;
                width: 0.313rem;
                height: 0.313rem;
                border-radius: 50%;
                margin-bottom: 0.25rem;
                background-color: #eaefff;
                box-shadow: inset 0.06rem 0.06rem 0.06rem 0 rgba(#000, 0.25);
            }

            &.playing::before {
                background-color: #fefefe;
                box-shadow:
                    0 0 0.5rem 0 $secondary-accent,
                    inset 0.06rem 0.25rem 0.06rem 0 $secondary-accent;
            }
        }
    }

    &__generate {
        width: 10rem;
        display: none;
        transition: transform 0.2s ease;
    }
}

.player_controls__music_img {
    display: none;
}

.player_controls__mobile_controls {
    display: none;
}
