@use "../../colors.scss" as *;

.menu {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    position: relative;
    padding: 4.5rem 0;

    hr {
        width: 100%;
        border: 0.06rem solid #1b1b1b;
        max-width: 55.75rem;
    }
}

.menu_item {
    width: 100%;
    max-width: 55.75rem;
    align-items: center;
    color: $light;
    border: 0.06rem solid transparent;
    padding: 0.4rem 1.5rem;
    border-radius: 0.25rem;
    gap: 1rem;
    display: flex;
    cursor: pointer;
    opacity: 0.6;
    transition:
        0.2s ease opacity,
        0.2s ease border;

    img {
        margin-left: auto;
    }

    &:hover {
        border: 0.06rem solid white;
        opacity: 1;
    }

    span:nth-child(3) {
        margin-left: auto;
        color: $accent;
        font-size: 0.8rem;
    }
}
