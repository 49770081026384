.songs_settings {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    &__header {
        padding: 2rem 0;
        display: flex;
        gap: 1rem;
        max-width: 40rem;

        button {
            flex: 0.5;
        }
    }
}
