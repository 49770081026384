.users {
    padding: 2rem 0;
    max-width: 50rem;
    margin: 0 auto;
    width: 100%;

    & > input {
        margin-bottom: 2rem;
    }
}
