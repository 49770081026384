@use "../../colors.scss" as *;

.energy_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.125rem;
    font-size: 1rem;
    color: white;
    transition: all 0.3s ease;
    height: 100%;
    border: 0.06rem solid $neutral;
    cursor: pointer;

    &--active {
        color: $secondary-accent;
    }

    img {
        width: 1.25rem;
    }
}

.energy_modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;

    &__backdrop {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($container-bg, 0.5);
    }

    &__container {
        position: relative;
        z-index: 2;
        background-color: $container-bg;
        border: 0.06rem solid $neutral;
        max-width: 45rem;
        width: 100%;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        max-height: 100%;
        overflow: auto;
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__item {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 3rem;
        gap: 1rem;
    }

    &__buttons {
        display: flex;
        gap: 1rem;
        margin-top: 1rem;
    }

    &__del {
        border: 0.06rem solid $neutral;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s ease all;

        img {
            width: 1.25rem;
        }

        &:hover {
            background-color: $secondary-accent;
        }
    }
}
