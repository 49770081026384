@use "../../../colors.scss" as *;

.post_form {
    margin: 0 auto;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    min-height: 14.625rem;
    width: 50%;
    max-width: 43rem;
    padding-left: 2rem;

    &__text {
        flex: 1;
        position: relative;
    }

    &__icons {
        position: absolute;
        left: 0.125rem;
        bottom: 0.4rem;
        display: flex;
        cursor: pointer;
        background-color: #0f0f0f;
        padding: 0.5rem 1.5rem 1rem 1.5rem;
        border-top-right-radius: 0.25rem;

        img {
            opacity: 0.9;

            &:hover {
                opacity: 1;
            }
        }
    }

    textarea {
        width: 100%;
        border-radius: 0.25rem;
        background-color: #0f0f0f;
        padding: 1.5rem;
        padding-bottom: 4rem;
        font-size: 1rem;
        color: $light;
        height: 9.5rem;
        resize: none;
        border: none;
        box-shadow:
            0.125rem 0.125rem 0.125rem 0 #3d3d3d,
            inset 0.2px 0.2px 1px rgba(#e0e0e0, 50%);

        &:focus {
            outline: 0.125rem solid $accent;
        }
    }

    &__publish {
        button {
            background-color: transparent;
            border: none;
            border-radius: 50%;
            cursor: pointer;
        }

        img {
            width: 5.5rem;
        }

        p {
            display: flex;
            gap: 0.25rem;
            align-items: center;
            margin-top: 1.25rem;
            color: $light;

            font-size: 0.75rem;
            justify-content: center;

            &::before {
                content: "";
                display: block;
                background-color: $light;
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                box-shadow: inset 0.06rem 0.06rem 0.06rem 0 $accent;
                margin-bottom: 0.25rem;
            }
        }
    }
}
