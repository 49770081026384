@use "../../colors.scss" as *;

.input {
    font-size: 1rem;
    padding: 0.625rem 1.75rem;
    color: $light;
    background-color: transparent;
    border-radius: 0.125rem;
    transition: border 0.3s ease;
    border: 0.06rem solid $neutral;
    width: 100%;

    &::placeholder {
        color: $neutral;
    }

    &:focus {
        outline: 0.06rem solid $light;
    }
}
