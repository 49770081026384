@use "../../../colors.scss" as *;

.post_modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 10;

    &__backdrop {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background-color: rgba(black, 0.5);
    }

    &__container {
        position: relative;
        z-index: 2;
        background-color: $container-bg;
        border: 0.06rem solid $neutral;
        max-width: 45rem;
        width: 100%;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        max-height: 100%;
        overflow: auto;
    }

    &__img {
        aspect-ratio: 1/1;
        width: 25rem;
        margin: 0 auto;
        object-fit: cover;
    }

    &__buttons {
        display: flex;
        gap: 1rem;
        margin-top: 1rem;
    }

    input[type="file"] {
        display: none;
    }

    .close {
        top: 1rem;
        right: 1rem;
    }

    &__songs {
        max-height: 20rem;
        overflow: auto;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__embed {
        width: 100%;
        height: auto;
        position: relative;
        margin-top: 24px;

        iframe {
            width: 100%;
            aspect-ratio: 560 / 315;
        }
    }

    &__tiktok_embed {
        width: 100%;
        height: auto;
        position: relative;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        iframe {
            height: 615px;
        }
    }
}
