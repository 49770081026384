.modal_container {
    background: #050505;
    border-radius: 0.75rem;
    padding: 0.75rem 1.5rem;
    overflow: auto;
    z-index: 10;
    top: 0.75rem;
    left: 0.75rem;
    right: 0.75rem;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
}
