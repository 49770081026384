@use "../../../colors.scss" as *;

.song_tile {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    cursor: pointer;
    border-bottom: 0.125rem solid transparent;
    transition: 0.2s ease border;
    padding-bottom: 0.25rem;

    &:hover {
        border-bottom: 0.125rem solid lighten($container-bg, 5%);
    }

    &__num {
        color: $light;
        font-size: 1.25rem;

        width: 1.5rem;
    }

    &__img {
        width: 3.75rem;
        height: 3.75rem;
        object-fit: cover;
        border-radius: 0.25rem;
    }

    &__header {
        font-size: 1.25rem;
        color: $light;
        margin-bottom: 0.25rem;
        display: flex;
        gap: 0.5rem;

        img {
            width: 1rem;
            height: 1rem;
        }
    }

    &__artist {
        color: $neutral-light;
    }

    &__heart {
        margin-left: auto;
        margin-bottom: auto;
        cursor: pointer;
    }

    &--active .song_tile__header {
        color: $accent;
        display: flex;

        &::after {
            content: "";
            display: block;
            width: 1rem;
            height: 1rem;
            margin-top: 0.1rem;
            background-image: url("../../../assets/wave.svg");
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    &__header--post_active {
        color: $accent;
    }
}
