@use "../../colors.scss" as *;

.theme_toggle {
    border: 0.1rem solid white;
    width: 60px;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 12px;
    position: absolute;
    bottom: 2rem;

    div {
        width: 30px;
        height: 30px;
        display: grid;
        place-content: center;
        transform: translate(30px);
        transition: all 0.3s ease;
    }

    &--light {
        div {
            width: 30px;
            height: 30px;
            display: grid;
            place-content: center;
            transform: translate(0px);
            transition: all 0.3s ease;
        }
    }

    img {
        width: 22px;
        height: 22px;
    }
}
