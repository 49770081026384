.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;

    &__speaker {
        width: 8.25rem;
    }

    &__logo {
        width: 2.5rem;
    }
}
