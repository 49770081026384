@use "../../colors" as *;

.notification {
    height: 1.2rem;
    font-size: 1rem;
    color: $accent;
    cursor: pointer;
    margin-bottom: 0.5rem;
    font-family: "OCR B Std Medium", "Courier New", Courier, monospace;

    &--error {
        color: $secondary-accent;
    }
}
