@use "../../colors.scss" as *;

.select {
    position: relative;
    z-index: 2;
    cursor: pointer;

    &:first-of-type {
        z-index: 3;
    }

    &:last-of-type {
        z-index: 1;
    }

    img {
        width: 1.5rem;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.75rem 1.5rem;
        border: none;
        border-radius: 0.125rem;
        font-size: 1rem;
        color: white;
        text-transform: none;
        transition: all 0.3s ease;
        border: 0.06rem solid $neutral;
    }

    &__body {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 3rem;
        width: 100%;
        font-size: 1.125rem;
        flex: 1;
        border-radius: 0.25rem;
        color: white;
        border: none;
        max-height: 12.5rem;
        overflow: auto;
    }

    &__item {
        width: 100%;
        padding: 1.125rem 1.5rem;
        cursor: pointer;
        background: #131313;
        border-bottom: 1px solid #1d1d1d;

        &:hover {
            background-color: #1d1d1d;
        }
    }
}
