.generating {
    position: fixed;
    left: 0;
    right: 0;
    padding: 0.75rem;
    z-index: 10;
    top: 2.5rem;
    bottom: 3rem;

    &__container {
        background-color: black;
        border-radius: 0.5rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
