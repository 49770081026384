@use "../../../colors.scss" as *;

.song_preview {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    flex: 1;
    gap: 0.25rem;

    &--generate {
        padding-top: 0;
    }

    &__img {
        height: 100%;
        border-radius: 0.25rem;

        &--loader {
            border-radius: 50%;
            animation: spin 8s linear infinite;
        }
    }

    &__img_container {
        flex: 1;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        margin-bottom: 0.25rem;
    }

    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__icons {
        display: flex;
        gap: 0.3rem;
        cursor: pointer;
    }

    h3 {
        font-size: 1.25rem;
        color: $light;
    }

    p {
        color: $neutral-light;
        width: 100%;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
